export const BackIcon = () => (
  <svg
    height="512px"
    id="Back"
    version="1.1"
    viewBox="0 0 512 512"
    width="512px"
    xmlns="http://www.w3.org/2000/svg">
    <polygon points="352,128.4 319.7,96 160,256 160,256 160,256 319.7,416 352,383.6 224.7,256 " />
  </svg>
);

export const BackspaceIcon = () => (
  <svg
    fill="#000000"
    height="800px"
    id="Backspace"
    viewBox="0 0 44.18 44.18"
    width="800px"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M10.625,5.09L0,22.09l10.625,17H44.18v-34H10.625z M42.18,37.09H11.734l-9.375-15l9.375-15H42.18V37.09z" />
      <polygon
        points="18.887,30.797 26.18,23.504 33.473,30.797 34.887,29.383 27.594,22.09 34.887,14.797 33.473,13.383 26.18,20.676 
		18.887,13.383 17.473,14.797 24.766,22.09 17.473,29.383 	"
      />
    </g>
  </svg>
);

export const CloseIcon = () => (
  <svg
    height="512px"
    id="Close"
    version="1.1"
    viewBox="0 0 512 512"
    width="512px"
    xmlns="http://www.w3.org/2000/svg">
    <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
  </svg>
);

export const RulesIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.3334 29.3333H6.66675C5.60588 29.3333 4.58847 28.9119 3.83832 28.1618C3.08818 27.4116 2.66675 26.3942 2.66675 25.3333V4.00001C2.66675 3.64638 2.80722 3.30724 3.05727 3.0572C3.30732 2.80715 3.64646 2.66667 4.00008 2.66667H22.6667C23.0204 2.66667 23.3595 2.80715 23.6096 3.0572C23.8596 3.30724 24.0001 3.64638 24.0001 4.00001V20H29.3334V25.3333C29.3334 26.3942 28.912 27.4116 28.1618 28.1618C27.4117 28.9119 26.3943 29.3333 25.3334 29.3333ZM24.0001 22.6667V25.3333C24.0001 25.687 24.1406 26.0261 24.3906 26.2761C24.6407 26.5262 24.9798 26.6667 25.3334 26.6667C25.687 26.6667 26.0262 26.5262 26.2762 26.2761C26.5263 26.0261 26.6667 25.687 26.6667 25.3333V22.6667H24.0001ZM21.3334 26.6667V5.33334H5.33341V25.3333C5.33341 25.687 5.47389 26.0261 5.72394 26.2761C5.97399 26.5262 6.31313 26.6667 6.66675 26.6667H21.3334ZM8.00008 9.33334H18.6667V12H8.00008V9.33334ZM8.00008 14.6667H18.6667V17.3333H8.00008V14.6667ZM8.00008 20H14.6667V22.6667H8.00008V20Z"
      fill="#1D1D21"
    />
  </svg>
);

export const StatsIcon = () => (
  <svg
    fill="#000000"
    height="800px"
    id="Stats"
    viewBox="0 0 294 294"
    width="800px"
    xmlns="http://www.w3.org/2000/svg">
    <g>
      <path d="M279,250H15c-8.284,0-15,6.716-15,15s6.716,15,15,15h264c8.284,0,15-6.716,15-15S287.284,250,279,250z" />
      <path
        d="M30.5,228h47c5.247,0,9.5-4.253,9.5-9.5v-130c0-5.247-4.253-9.5-9.5-9.5h-47c-5.247,0-9.5,4.253-9.5,9.5v130
		C21,223.747,25.253,228,30.5,228z"
      />
      <path
        d="M123.5,228h47c5.247,0,9.5-4.253,9.5-9.5v-195c0-5.247-4.253-9.5-9.5-9.5h-47c-5.247,0-9.5,4.253-9.5,9.5v195
		C114,223.747,118.253,228,123.5,228z"
      />
      <path
        d="M216.5,228h47c5.247,0,9.5-4.253,9.5-9.5v-105c0-5.247-4.253-9.5-9.5-9.5h-47c-5.247,0-9.5,4.253-9.5,9.5v105
		C207,223.747,211.253,228,216.5,228z"
      />
    </g>
  </svg>
);
