import styles from './index.module.scss';

const Footer = () => {
  return (
    <footer className={styles.container}>
      <p>made with ♡ by devthena</p>
    </footer>
  );
};

export default Footer;
